import {
  defineDirectory,
  DirectoryInstance,
} from 'best-modules/plugins/directory/directory'
import { DirectoryName } from './types'
import { months, yearsFrom2024 } from './local'

const {
  DirectoryPlugin,
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
} = (() => {
  return defineDirectory<DirectoryName>({
    companyTypes: '/admin/list/companyTypes',
    companyList: '/admin/list/companies',
    months: { items: months },
    yearsFrom2024: { items: yearsFrom2024, indexedKey: 'year' },
    caList: '/admin/electronicSignature/caList',
    packageStatuses: '/admin/list/reportPackageStatuses',
    userList: '/admin/list/users',
    reportList: '/admin/list/reports',
    tariffTypes: '/admin/list/tariffs',
  })
})()

export default DirectoryPlugin

export {
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $directory: DirectoryInstance<string>
  }
}
