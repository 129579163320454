<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getReport')">
      <div class="d-flex justify-space-between mb-3">
        <div>
          <v-app-bar-title class="font-weight-bold mb-2">
            {{ $route?.meta?.title }}
          </v-app-bar-title>
          <span class="d-block">
            Даний розділ відображає список сформованих і поданих звітів до НБУ
          </span>
        </div>
        <div class="d-flex align-center">
          <v-btn-group
            v-if="userAccessRights.includes('report.package.create')"
            color="primary"
            rounded
            density="comfortable">
            <v-btn @click="createPackage()">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Створити пакет
            </v-btn>
            <v-btn size="x-small" style="border-left: 1px solid white">
              <v-menu activator="parent">
                <b-action-list
                  :actions="[
                    {
                      action: () => parsePackage('excel'),
                      icon: 'best:xls-variant',
                      title: 'Імпортувати з Excel',
                    },
                    {
                      action: () => parsePackage('json'),
                      icon: 'mdi-code-block-braces',
                      title: 'Імпортувати з JSON',
                    },
                  ]"></b-action-list>
              </v-menu>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-btn-group>
        </div>
      </div>
      <v-divider></v-divider>
      <v-fade-transition>
        <div class="mt-2 mb-3 d-flex justify-space-between align-center">
          <div style="height: 38px">
            <v-fade-transition>
              <div v-show="selectedPackages.length">
                <div class="d-flex align-center gap-3">
                  <v-btn
                    v-if="
                      userAccessRights.includes('electronicSignature.signature')
                    "
                    color="amber-darken-1"
                    :disabled="!selectedPackages.length"
                    class="text-white"
                    @click="signPackages">
                    Підписати
                  </v-btn>

                  <v-btn
                    class="ma-0 text-white"
                    color="#EE4266"
                    :loading="$loading.isLoading('sendPackages')"
                    @click="sendPackages">
                    Надіслати пакети
                  </v-btn>
                  <v-btn
                    class="ma-0"
                    variant="outlined"
                    @click="getPackageStatus">
                    Перевірити статус
                  </v-btn>
                </div>
              </div>
            </v-fade-transition>
          </div>

          <div class="d-flex align-center">
            <div class="d-flex gap-2">
              <v-btn
                v-if="userAccessRights.includes('template.excel')"
                v-tooltip="'Завантажити шаблон'"
                class="ma-0"
                icon
                variant="plain"
                density="comfortable"
                size="small"
                @click="getPackageTemplateMethod">
                <v-icon class="cursor-pointer" style="color: var(--grey)">
                  mdi-page-previous-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="userAccessRights.includes('template.domains')"
                v-tooltip="'Завантажити довідники'"
                class="ma-0"
                icon
                variant="plain"
                density="comfortable"
                size="small"
                @click="getPackageDomainsMethod">
                <v-icon class="cursor-pointer" style="color: var(--grey)">
                  mdi-list-box
                </v-icon>
              </v-btn>
              <v-btn
                v-if="selectedPackages.length"
                v-tooltip="'Друкувати'"
                class="ma-0"
                icon
                variant="plain"
                density="comfortable"
                size="small">
                <v-icon class="cursor-pointer" style="color: var(--grey)">
                  mdi-printer-outline
                </v-icon>
              </v-btn>

              <v-btn
                v-if="selectedPackages.length"
                v-tooltip="'Зберегти звіт'"
                class="ma-0"
                icon
                variant="plain"
                density="comfortable"
                size="small">
                <v-icon class="cursor-pointer" style="color: var(--grey)">
                  mdi-content-save
                </v-icon>
              </v-btn>

              <v-btn
                v-if="
                  selectedPackages.length &&
                  userAccessRights.includes('report.package.delete')
                "
                v-tooltip="'Видалити звіт'"
                class="ma-0"
                icon
                variant="plain"
                density="comfortable"
                size="small"
                :loading="$loading.isLoading('deletePackage')"
                @click="deletePackageMethod">
                <v-icon class="cursor-pointer" style="color: var(--grey)">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-fade-transition>

      <div class="mt-7">
        <span class="mb-2 d-block">
          Обрано пакетів: <b>{{ selectedPackages.length }}</b>
        </span>

        <b-server-table
          v-model="selectedPackages"
          :request-func="requestFunc"
          :socket-url="packageSingleChannel"
          :socket-func="socketFunc"
          show-select
          show-filters
          show-search
          :headers="packageListHeaders"
          @click:row="
            userAccessRights.includes('report.package.single') &&
              $router.push({
                name: 'package-single',
                params: { id: $event.id },
              })
          ">
          <template #[`item.created_at`]="{ item }">
            {{
              new CustomDate(item.created_at.replace('T', ' ')).toString({
                time: true,
              })
            }}
          </template>
          <template #[`item.code`]="{ item }">
            <div
              v-tooltip="item.isValidated ? 'Провалідовано' : 'Непровалідовано'"
              class="d-flex align-center">
              <span class="mr-2">{{ item?.code }}</span>
              <v-chip
                size="x-small"
                variant="flat"
                class="cursor-default d-flex justify-center align-center"
                :color="item.isValidated ? 'green' : 'red'">
                <v-icon>mdi-shield-check</v-icon>
              </v-chip>
            </div>
          </template>
          <template #[`item.author`]="{ item }">
            {{ getPersonName(item.author, { initials: true }) }}
          </template>
          <template #[`item.client`]="{ item }">
            {{ item?.person?.name }}
          </template>
          <template #[`item.inn`]="{ item }">
            {{ item?.person?.code }}
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              :value="1"
              color="secondary"
              density="comfortable"
              rounded="pill">
              {{ item.status?.name }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <b-action-menu
              :actions="[
                {
                  title: 'Скопіювати пакет',
                  icon: 'mdi-content-copy',
                  disabled: !userAccessRights.includes('report.package.copy'),
                  action: () => copyPackageMethod(item),
                },
                {
                  title: 'Експортувати пакет в JSON',
                  icon: 'mdi-download-circle-outline',
                  action: () => exportPackage(item.id),
                },
              ]"></b-action-menu>
          </template>
          <template #bottom> </template>
          <template #filters>
            <b-server-filter-item
              v-slot="{ props }"
              query-key="statusId"
              directory="packageStatuses">
              <filter-select
                v-bind="props"
                label="Статус"
                item-value="id"
                item-title="name"></filter-select>
            </b-server-filter-item>
            <b-server-filter-item
              v-slot="{ props }"
              query-key="authorId"
              :directory="{
                name: 'userList',
                query: { column: 'companyId', value: 1 },
                force: true,
              }">
              <filter-select
                v-bind="props"
                label="Автор"
                item-value="id"
                :item-title="getPersonName"></filter-select>
            </b-server-filter-item>
          </template>
        </b-server-table>
      </div>
    </b-section-loader>
  </div>
</template>
<script lang="ts">
import {
  deletePackage,
  getPackageDomains,
  getPackageTemplate,
  getReportPackageList,
  getReportSingle,
  sendPackage as sendPackageRequest,
  exportPackage,
} from '@/request/report'
import { computed, inject, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  BSectionLoader,
  BServerTable,
  BServerFilterItem,
  BActionList,
  BActionMenu,
} from 'best-modules/components'
import { CustomDate, getPersonName } from 'best-modules/utils'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { openDialog } from '@/plugins/dialog'
import type { PackageParseType, Report } from '@/utils/types'
import { packageListHeaders } from '@/utils/tableHeaders'
import { rerenderKey } from '@/injection-keys'
import { useBreadcrumb } from '@/plugins/breadcrumb'
import FilterSelect from '@/components/inputs/filter-select.vue'
import { download, getFile } from 'best-modules/utils/fileHandlers'
import { packageSingleChannel } from '@/socket'
import { storeToRefs } from 'pinia'
import { useUser } from '@/store/user'

export default {
  name: 'ReportSingle',
  components: {
    FilterSelect,
    BSectionLoader,
    BActionMenu,
    BServerTable,
    BServerFilterItem,
    BActionList,
  },
  data() {
    return {
      menu: false,
      CustomDate,
      packageListHeaders,
      packageSingleChannel,
    }
  },
  methods: {
    getPersonName,
    exportPackage,
  },
  setup() {
    const route = useRoute()
    const reportId = computed<string>(() => route.params.id as string)
    const report: Report = reactive({}) as Report
    const rerender = inject(rerenderKey)
    const breadcrumb = useBreadcrumb()
    const { userAccessRights } = storeToRefs(useUser())

    const packageList = ref([])
    const selectedPackages = ref([])

    const requestFunc = query => {
      return getReportPackageList(reportId.value, query).then(res => {
        packageList.value = res.data
        return res
      })
    }
    const socketFunc = (res, items) => {
      if (items?.map(i => i.id).includes(res.id)) {
        return items.map(i => {
          if (i.id === res.id) {
            return res
          } else {
            return i
          }
        })
      } else {
        return null
      }
    }

    handleAsync('getReport', () => {
      return getReportSingle(reportId.value).then(res => {
        Object.assign(report, res)
        breadcrumb.set([
          {
            title: `Звітність за ${report?.period || ''}`,
            index: 1,
          },
        ])
      })
    })

    const signPackages = () => {
      const packages = packageList.value.filter(p => {
        return selectedPackages.value.includes(p.id) && p.statusId === 2
      })

      if (!packages.length) {
        return setSnackbar({
          text: 'Ні один з обраних пакетів не можна підписати',
          color: 'warning',
        })
      }

      if (packages.map(p => p.isValidated).includes(false)) {
        return setSnackbar({
          text: 'Можна підписати тільки провалідовані пакети',
          color: 'warning',
        })
      }

      return openDialog({
        name: 'Signature',
        dialogData: { packages: selectedPackages.value },
        params: {
          cb: res => {
            if (res.message) {
              rerender()
            }
          },
        },
      })
    }

    const createPackage = () => {
      openDialog({
        name: 'PackageManually',
        action: 'create',
        dialogData: {
          reportId: reportId.value,
        },
        params: {
          cb: rerender,
          submitLabel: 'Створити',
        },
      })
    }
    const parsePackage = (parseFrom: PackageParseType) => {
      openDialog({
        name: 'Package',
        action: 'create',
        dialogData: {
          reportId: reportId.value,
          parseFrom,
        },
        params: {
          cb: rerender,
          submitLabel: 'Підтвердити',
        },
      })
    }

    const sendPackages = () => {
      const packages = packageList.value
        .filter(p => {
          return selectedPackages.value.includes(p.id) && p.statusId === 3
        })
        .map(p => p.id)

      if (!packages.length) {
        return setSnackbar({
          text: 'Ні один з обраних пакетів не може бути відправленний',
          color: 'warning',
        })
      }

      return handleAsync('sendPackages', () => {
        return sendPackageRequest(packages).then(rerender)
      })
    }

    const getPackageStatus = () => {
      const packages = packageList.value
        .filter(p => {
          return (
            selectedPackages.value.includes(p.id) &&
            [6, 9, 10].includes(p.statusId)
          )
        })
        .map(p => p.id)

      if (!packages.length) {
        return setSnackbar({
          text: 'Ні в одному з обраних пакеті не можливо перевірити статус',
          color: 'warning',
        })
      }

      return openDialog({
        name: 'Signature',
        dialogData: {
          isGetPackageStatus: true,
          packages,
        },
        params: {
          cb: res => {
            if (res.message) {
              rerender()
            }
          },
        },
      })
    }

    const deletePackageMethod = () => {
      handleAsync('deletePackage', () => {
        return deletePackage(selectedPackages.value)
          .then(rerender)
          .catch(error => {
            console.error('Error deleting package:', error)
          })
      })
    }

    const getPackageTemplateMethod = () => {
      handleAsync('getPackageTemplate', () => {
        return getPackageTemplate()
          .then(async res => {
            if (res.template) {
              const fileName = 'import_fc.xlsx'
              const file = (await getFile(res.template)) as string
              await download(file, fileName)
            }
          })
          .catch(error => {
            console.error('Error get package template:', error)
          })
      })
    }

    const getPackageDomainsMethod = () => {
      handleAsync('getPackageDomains', () => {
        return getPackageDomains()
          .then(async res => {
            if (res.template) {
              const fileName = 'Domains(Довідники).xlsx'
              const file = (await getFile(res.template)) as string
              await download(file, fileName)
            }
          })
          .catch(error => {
            console.error('Error get package domains:', error)
          })
      })
    }

    const copyPackageMethod = item => {
      openDialog({
        name: 'PackageCopy',
        dialogData: {
          itemId: item.id,
        },
      })
    }

    return {
      reportId,
      report,
      signPackages,
      selectedPackages,
      createPackage,
      parsePackage,
      deletePackageMethod,
      getPackageTemplateMethod,
      getPackageDomainsMethod,
      copyPackageMethod,
      requestFunc,
      socketFunc,
      userAccessRights,
      sendPackages,
      getPackageStatus,
    }
  },
}
</script>
